<template>
  <van-nav-bar title="" left-arrow @click-left="goBack" :border="false" />
  <div class="seller-account-form">
    <div class="form-wrap">
      <div class="form-title">{{$t('field.address')}}</div>
      <div class="field-wrap">
        <van-field
          v-model="qrCode"
          class="form-field with-suffix"
          size="large"
          maxlength="64"
          clearable
          :placeholder="$t('placeholder.default')"
        />
        <div class="suffix-wrap" @click="goToQrcode">
          <van-icon name="scan" size="24" />
        </div>
      </div>
      <div class="form-title">{{$t('field.remark')}}</div>
      <van-field
          v-model="remark"
          class="form-field"
          size="large"
          maxlength="32"
          clearable
          :placeholder="$t('placeholder.optional')"
      />
      <div class="form-title">{{$t('field.verifyCode')}}</div>
      <div class="field-wrap">
        <van-field
            v-model="verifyCode"
            class="form-field with-suffix"
            size="large"
            type="digit"
            maxlength="6"
            clearable
            :placeholder="$t('placeholder.verifyCode')"
        />
        <van-button v-if="coldDownTime === 0" type="primary" class="sms-btn" @click="sendVerifyCode">{{$t('button.smsBtn')}}</van-button>
        <van-button v-else type="primary" class="sms-btn" :disabled="true">{{$t('button.smsColdDown')}} {{coldDownTime}}{{$t('button.second')}}</van-button>
      </div>
    </div>
    <van-button
      size="large"
      type="primary"
      block
      @click="handleAddAccount"
      :disabled="confirmDisabled"
    >{{$t('button.submit')}}</van-button>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import i18n, {Local} from '@/assets/lang/index';
import {addUserAccount, updateUserAccount, sendAddUserAccountSms, sendUpdateUserAccountSms} from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    return {
      goBack,
    };
  },
  
  data() {
    return {
      remark: '',
      qrCode: '',
      ...this.$AppData.dataStore,
      verifyCode: '',
      coldDownTime: 0,
      coldDownInterval: false,
    };
  },
  
  computed: {
    confirmDisabled() {
      return  !this.qrCode || this.verifyCode.length != 6;
    },
  },
  
  mounted() {
    this.$AppData.clearDataStore();
    // this.fetchData();
  },
  
  methods: {
    sendVerifyCode() {
      const doPost = this.id ? sendUpdateUserAccountSms : sendAddUserAccountSms;
      doPost().then(() => {
        this.coldDownTime = 60;
        this.coldDown();
      }).catch(error => {
      });
    },
    handleAddAccount() {
      const doPost = this.id ? updateUserAccount : addUserAccount;
      doPost({
        id: this.id,
        remark: this.remark,
        address: this.qrCode,
        code: this.verifyCode,
      }).then(res => {
        this.$toast({ type: 'success', message: i18n.global.t('notify.operateSuccess') });
        this.$router.back();
      // }).catch(error => {
      //   this.verifyCode = '';
      });
    },
    goToQrcode() {
      this.$AppData.saveDataStore({
        remark: this.remark,
        qrCode: this.qrCode,
        verifyCode: this.verifyCode,
      });
      this.$router.push('/qrcode');
    },
    coldDown() {
      if (this.coldDownInterval) {
        clearInterval(this.coldDownInterval);
      }
      this.coldDownInterval = setInterval(() => {
        this.coldDownTime = this.coldDownTime - 1;
        if (this.coldDownTime ===0) {
          clearInterval(this.coldDownInterval);
        }
      }, 1000)
    },
  },
};
</script>

<style lang="less">
.seller-account-form {
  padding: 12px;
  padding-bottom: 50px;
  .form-wrap {
    padding: 0 12px 16px;
    margin-bottom: 12px;
    background: #fff;
    border-radius: 2px;
    overflow: hidden;
    .form-title {
      margin: 16px 0;
      font-size: 14px;
      color: #28293D;
      font-weight: 400;
    }
    .field-wrap {
      position: relative;
      .suffix-wrap {
        position: absolute;
        top: 0;
        right: 12px;
        width: 30px;
        line-height: 46px;
        text-align: right;
        font-weight: 400;
        color: #6F6F93;
        i, span {
          font-size: 16px;
          vertical-align: middle;
        }
        .triangle {
          width: 8px;
          margin-left: 12px; 
          vertical-align: middle;
        }
      }
    }
    .form-field {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      background: #F9FAFB;
      border-radius: 4px;
      &.with-suffix {
        padding-right: 40px;
      }
      &.readonly {
        background: transparent;
      }
    }
    .sms-btn {
      position: absolute;
      right: 6px;
      top: 8px;
      height: 36px;
    }
    .field-extra {
      padding: 8px 0;
      font-size: 10px;
      color: #6F6F93;
      line-height: 12px;
      font-weight: 400;
      .highlight {
        color: var(--van-primary-color);
      }
    }
  }
}
</style>
